<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    color="white"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    width="300"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item-content>
        <v-img
          id="logoCompany"
          src="@/assets/logo-hipmibpd.png"
          max-height="90"
          contain
          @click="iconClick"
        />
        
      </v-list-item-content>
      <!-- <v-card>
        {{this.user.c_fname}}
      </v-card> -->
      <v-list-item-content style="text-align: center; color: black">
        <span>
          {{ user.c_entity }}
        </span>
        <span>
          {{ user.c_fname }}
        </span>
        <span>
          {{ user.c_email }}
        </span>
        <span>
          {{ user.c_type }}
        </span>
      </v-list-item-content>
    </v-list>

    <!-- <v-divider class="mb-2" /> -->

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />

        <!-- <v-divider
          v-if="item.separator"
          :key="`divider-${i}`"
          class="mb-2"
        /> -->
        <div
          v-if="item.separator"
          :key="`divider-${i}`"
          class="mb-2"
          style="border-bottom: 1px solid #691570"
        />
      </template>
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "Beranda",
        to: "/",
        separator: false,
      },
    ],

    user: {
      c_fname: JSON.parse(localStorage.getItem("userinfo")).member_name,
      c_email: JSON.parse(localStorage.getItem("userinfo")).member_email,
      c_type: JSON.parse(localStorage.getItem("userinfo")).member_type,
      c_entity: JSON.parse(localStorage.getItem("userinfo")).member_entity,
    },
  }),

  computed: {
    ...mapState({
      barImage: (state) => state.setting.barImage,
      barColor: (state) => state.setting.barColor,
      userinfo: (state) => state.user.userinfo,
    }),
    drawer: {
      get() {
        return this.$store.state.setting.drawer;
      },
      set(val) {
        this.$store.commit("setting/SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },

  created() {
    this.initialize();
  },

  mounted() {
    // this.user = JSON.parse(this.userinfo)
  },

  methods: {
    initialize() {
      const userInfo = JSON.parse(localStorage.getItem("userinfo"));
      const userMenus = userInfo.menu_function;

      if (userMenus.includes("MEMBER_VIEW")) {
        this.items.push({
          icon: "mdi-account-multiple",
          title: "Keanggotaan",
          to: "/keanggotaan",
          notifkey: "menu_keanggotaan",
          separator: false,
        });
      }

      if (userMenus.includes("COMPANY_VIEW")) {
        this.items.push({
          icon: "mdi-domain",
          title: "Perusahaan",
          to: "/perusahaan",
          notifkey: "menu_perusahaan",
          separator: false,
        });
      }

      if (userMenus.includes("UNIVERSITY_VIEW")) {
        this.items.push({
          icon: "mdi-school",
          title: "Universitas",
          to: "/universitas",
          notifkey: "menu_universitas",
          separator: false,
        });
      }

      if (userMenus.includes("ORGSTRUCT_VIEW")) {
        this.items.push({
          icon: "mdi-lan",
          title: "Struktur Organisasi",
          to: "/struktur",
          notifkey: "menu_struktur",
          separator: false,
        });
      }

      if (userMenus.includes("WPLAN_VIEW")) {
        this.items.push({
          icon: "mdi-briefcase",
          title: "Program Kerja",
          to: "/programkerja",
          notifkey: "menu_programkerja",
          separator: false,
        });
      }

      if (userMenus.includes("EVENT_VIEW")) {
        this.items.push({
          icon: "mdi-calendar",
          title: "Kalender",
          to: "/event",
          notifkey: "menu_event",
          separator: false,
        });
      }

      if (userMenus.includes("ARTICLE_VIEW")) {
        this.items.push({
          icon: "mdi-newspaper-variant",
          title: "Kegiatan",
          to: "/artikel",
          notifkey: "menu_artikel",
          separator: false,
        });
      }

      // this.items.push(
      //   {
      //     icon: 'mdi-account',
      //     title: 'Galeri',
      //     to: '/galeri',
      //     notifkey: 'menu_galeri',
      //     separator: false,
      //   },
      // )

      if (userMenus.includes("LETTER_VIEW")) {
        this.items.push({
          icon: "mdi-email",
          title: "Format Surat",
          to: "/formatsurat",
          notifkey: "menu_formatsurat",
          separator: false,
        });
      }

      if (userMenus.includes("LEGAL_VIEW")) {
        this.items.push({
          icon: "mdi-mail",
          title: "Legalitas",
          to: "/legalitas",
          notifkey: "menu_legalitas",
          separator: false,
        });
      }

      // if (userMenus.includes('TICKET_VIEW')) {
      //   this.items.push(
      //     {
      //       icon: 'mdi-inbox-full',
      //       title: 'Tiket',
      //       to: '/tiket',
      //       notifkey: 'menu_tiket',
      //       separator: false,
      //     },
      //   )
      // }

      // if (userMenus.includes('MENU_GRANT_REVOKE')) {
      //   this.items.push(
      //     {
      //       icon: 'mdi-account-cog',
      //       title: 'Pengaturan Peran User',
      //       to: '/peranuser',
      //       notifkey: 'menu_peranuser',
      //       separator: false,
      //     },
      //   )
      // }
    },

    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },

    iconClick() {
      this.$router.push({ name: "Dashboard" }).catch(() => {});
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>

<style scoped>
#logoCompany {
  cursor: pointer;
}
</style>
